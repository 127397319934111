import { Badge } from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { PRODUCT_BADGES } from 'utils/constants/productBadges';
import { ImageType, ProductBadgeConfig } from 'utils/types';

import * as S from './HeroCard.styles';

export type HeroCardProps = {
  cardImageMobile: ImageType;
  cardImage: ImageType;
  linkText: string;
  linkPath: string;
  callout: string;
  onCardClick: (text: string) => void;
};

const getBadeConfig = (badgeText: string): ProductBadgeConfig | null => {
  if (!badgeText) {
    return null;
  }

  const normalizedText = badgeText.trim().toLowerCase();

  switch (normalizedText) {
    case 'sale':
      return { ...PRODUCT_BADGES.SALE, text: badgeText };
    default:
      return { ...PRODUCT_BADGES.NEW, text: badgeText };
  }
};

/**
 * Cards for the hero component
 */
function HeroCard({
  cardImageMobile,
  cardImage,
  linkText,
  linkPath,
  callout,
  onCardClick,
}: HeroCardProps): JSX.Element {
  const badgeConfig = getBadeConfig(callout);

  return (
    // Cotainer is a gatsby link
    <S.Container to={linkPath} onClick={() => onCardClick(linkText)}>
      <S.TextsContainer>
        {badgeConfig ? (
          <Badge
            backgroundColor={badgeConfig.color}
            text={badgeConfig.text}
          ></Badge>
        ) : null}
        <S.LinkTextContainer>{linkText}</S.LinkTextContainer>
      </S.TextsContainer>
      <S.ImageContainer>
        <GatsbyImage
          image={cardImageMobile.gatsbyImageData}
          alt={cardImageMobile.description || ''}
          className="mobile-image"
          objectFit="contain"
        ></GatsbyImage>
        <GatsbyImage
          image={cardImage.gatsbyImageData}
          alt={cardImage.description || ''}
          className="desktop-image"
          objectFit="contain"
        ></GatsbyImage>
      </S.ImageContainer>
    </S.Container>
  );
}

export default HeroCard;
