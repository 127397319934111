import { BadgeProps } from '@everlywell/leaves';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';

import * as S from './DiscoveryCard.styles';

export type DiscoveryCardProps = {
  text?: string;
  title: string;
  url: string;
  image: IGatsbyImageData;
  badge?: BadgeProps;
  className?: string;
};

/**
 * Card for virtual care discovery section
 */
function DiscoveryCard({
  image,
  url,
  title,
  text,
  badge,
  className,
}: DiscoveryCardProps): JSX.Element {
  const handleCTAclick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.EVENTS.CLICKED_BUTTON,
        label: title,
      },
    });
    return false;
  };

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: title,
      },
    });
  }, [title]);

  return (
    <S.Container className={className} href={url} onClick={handleCTAclick}>
      {badge && <S.Badge {...badge} />}
      <S.ImageContainer>
        <S.Image alt={title} image={image} />
      </S.ImageContainer>
      <S.BodyContainer>
        <S.Title>{title}</S.Title>
        <S.Text>{text}</S.Text>
      </S.BodyContainer>
    </S.Container>
  );
}

export default DiscoveryCard;
