import {
  Container as RawContainer,
  size,
  Col as RawCol,
  mediaQueries,
  Button as RawButton,
  typography,
  Button,
  Container,
  colors,
  H3,
} from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Section = styled.div`
  position: relative;
  padding: ${size.md}px 0;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.lg}px 0;
  }
`;

export const InnerContent = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: 0;
  }
`;

export const Img = styled.img`
  display: none;
`;

export const DesktopImg = styled.img`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    margin-top: ${size.xl2}px;
    max-width: 72px;
  }
`;

export const CardWrapper = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 263px;
  }

  & > a {
    height: 100%;

    /* TODO: this keeps the images the same height */
    ${mediaQueries.forTabletHorizontalUp} {
      > div:first-of-type {
        min-height: 158px;
        max-height: 158px;
      }
    }
  }
`;

export const Headline = styled(H3)`
  text-align: center;
  margin-bottom: ${size.xl2}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.lg}px;
    text-align: left;
  }
`;

export const ContainerTests = styled(Container)`
  display: flex;
`;

export const MobileHeadline = styled(Headline)`
  margin-top: ${size.md}px;
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const DesktopHeadline = styled(Headline)`
  display: none;

  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
  }
`;

export const MobileShopCTAContainer = styled(RawContainer)`
  display: block;
  margin-top: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const MobileShopCTA = styled(Button)`
  width: 100%;
  max-width: 203px;
`;

export const DesktopShopCTAButton = styled(RawButton)`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    width: 176px;
    padding-left: 11px;
    padding-right: 11px;
  }
`;

export const MobileCardsCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentContainer = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-left: ${size.xl3}px;
  }
`;

export const DesktopCardsContainer = styled.div`
  flex: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${size.sm}px ${size.sm}px;
  max-width: 100%;

  ${mediaQueries.forDesktopUp} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 ${size.xl3}px 0 0;
    max-width: 1088px;
  }
`;

export const CardImage = styled(GatsbyImage)`
  > div {
    img {
      max-width: 100% !important;
      display: block !important;
      position: static !important;
    }
  }
`;

export const CardTitle = styled.h3`
  color: ${colors.green4};
  font-size: ${size.md}px;
  line-height: ${size.lg}px;
  font-weight: ${typography.weight.xbold};
`;
