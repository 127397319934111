import VirtualCareBenefitsSection from 'components/Homepage/VirtualCareBenefitsSection';
import React from 'react';

import { ContentWithImage, ImageType } from '../../../utils/types';

export type TelehealthBenefitsList = {
  title: string;
  image: ImageType;
};

export interface VirtualCareBenefitsContainerProps {
  content: {
    headline: string;
    subHeadline: string;
    listOfContentWithImages: ContentWithImage[];
  };
}

export const VirtualCareBenefitsContainer: React.FC<
  VirtualCareBenefitsContainerProps
> = ({ content }) => {
  if (!content) {
    return null;
  }

  const { listOfContentWithImages, headline, subHeadline } = content;

  const formattedListOfContent: TelehealthBenefitsList[] =
    listOfContentWithImages.map((item) => ({
      title: item.heading,
      image: {
        gatsbyImageData: item.image?.gatsbyImageData,
        description: item.image?.description || '',
      },
    }));

  return (
    <VirtualCareBenefitsSection
      benefits={formattedListOfContent}
      headline={headline}
      subHeadline={subHeadline}
    />
  );
};

export default VirtualCareBenefitsContainer;
