import { size, colors, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div<{
  backgroundColor: string;
  desktopHeight: number;
  tabletHeight: number;
}>`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${mediaQueries.forTabletVerticalUp} {
    /* Reverse order from tablet vertial and up */
    flex-direction: row-reverse;
    height: ${({ tabletHeight }) => `${tabletHeight}px`};
  }

  ${mediaQueries.forDesktopUp} {
    height: ${({ desktopHeight }) => `${desktopHeight}px`};
  }
`;

export const ContentContainer = styled.div`
  padding: 0 ${size.lg}px ${size.lg}px ${size.lg}px;
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl4}px ${size.xl3}px 0 ${size.xl3}px;
    flex-basis: 50%;
  }

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl5}px ${size.xl6}px 0 ${size.xl4}px;
    flex-basis: 60%;
  }
`;

export const TextsContainer = styled.div`
  /* Size provided by design team */
  max-width: 562px;
`;

export const ImageContainer = styled.div<{ tabletHeight: number }>`
  /* Gatsby image container */
  .main-image {
    width: 100%;
    border-radius: 0 0 0 120px;
  }

  ${mediaQueries.forTabletVerticalUp} {
    flex: 1;

    .main-image {
      display: flex;

      /* For tablet, the image doesn't extend the whole height */
      height: ${({ tabletHeight }) => `${tabletHeight - Number(size.xl7)}px`};
    }
  }

  ${mediaQueries.forDesktopUp} {
    .main-image {
      height: 100%;
    }
  }
`;

export const headline = styled.h1`
  ${typography.h2Text};

  /* Override DLS */
  font-weight: ${typography.weight.regular} !important;

  /* Override typography */
  color: ${colors.white} !important;
  text-align: center;
  margin-top: ${size.lg}px;
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.h3Text};
    text-align: left;
  }

  ${mediaQueries.forDesktopUp} {
    ${typography.h1Text};
  }
`;

export const subHeadline = styled.h2`
  ${typography.subtitle};

  /* Override DLS */
  font-weight: ${typography.weight.regular} !important;

  /* Override typography */
  color: ${colors.white} !important;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.h5Text};
    text-align: left;
  }

  ${mediaQueries.forDesktopUp} {
    ${typography.subtitle};
  }
`;

export const CardsContainerMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${size.lg}px;
  padding-left: ${size.md}px;
  padding-right: ${size.md}px;
  margin-top: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;
  }
`;

export const CardsContainerTabletUp = styled.div`
  display: none;

  ${mediaQueries.forTabletVerticalUp} {
    display: flex;
    gap: ${size.lg}px;
    flex-direction: row;
    position: absolute;
    bottom: -${size.xl4}px;
    width: 100%;
    justify-content: center;
  }

  ${mediaQueries.forDesktopUp} {
    left: ${size.xl4}px;
    width: fit-content;
    justify-content: left;
  }
`;
