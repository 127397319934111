import { Row, Card } from '@everlywell/leaves';
import { useCart } from 'components/Cart';
import { navigate } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Cookies from 'js-cookie';
import React, { useState, useContext } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CookieNames } from 'utils/constants/cookies';
import { customGatsbyLink } from 'utils/helpers/templateHelpers';
import { useMembershipProducts } from 'utils/hooks';
import { UserContext } from 'utils/hooks/useUserContext/context';
import { CTA, ProductsBySlugType } from 'utils/types';

import snowflakes from '../../../images/themes/Winter2020/snowflakes.svg';
import { AllProductPromosContext } from '../../../templates/homepage';
import { Themable, Theme } from '../../Themable';
import lines from './lines.svg';
import * as S from './styles';
declare global {
  interface Window {
    dataLayer: any;
  }
}

export type ProductCardType = {
  heading: string;
  subheading: string;
  callout?: string;
  image: IGatsbyImageData;
  number: number;
  slug: string;
  showRating: boolean;
  description: string;
  reviewData: {
    numOfReviews: number;
    rating: number;
  };
  productLink?: string;
};

export interface PopularTestsSectionProps {
  headline: string;
  mainCta: CTA;
  listOfContent: ProductCardType[];
  productsBySlug: ProductsBySlugType;
}

const getPriceForMembershipProduct = (
  price?: string,
  memberPrice?: string,
): string => {
  const priceAsNumber = Number(price);

  // Hide price when it is zero
  if (priceAsNumber === 0) {
    return '';
  }

  return memberPrice && price ? `$${priceAsNumber.toFixed(0)}` : ' ';
};

export const PopularTestsSection: React.FC<PopularTestsSectionProps> = ({
  headline,
  mainCta,
  listOfContent,
  productsBySlug,
}) => {
  const [itemsInCart, setItemsInCart] = useState<Array<string>>([]);

  const { userData } = useContext(UserContext);
  const allProductPromoData = useContext(AllProductPromosContext);
  const experimentCookie = Cookies.get(CookieNames.HomepageExperiment);

  const { addItemToCart } = useCart();

  const productsWithMembership = useMembershipProducts(
    userData,
    productsBySlug,
  );

  const handleClick = (navigateTo: string) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.HOMEPAGE_POPULAR_ALL_PRODUCTS_CTA,
      },
    });

    // gatsby SPA navigation
    navigate(navigateTo);
  };

  const handleAddToCartClick = (slug: string) => {
    setItemsInCart([...itemsInCart, slug]);

    // Remove 'added' UI state
    setTimeout(
      () => setItemsInCart(itemsInCart.filter((x) => x !== slug)),
      1200,
    );

    const product = productsWithMembership[slug];

    addItemToCart({
      variant_id: product.variantId,
      quantity: 1,
    });

    ewAnalytics.track({
      data: {
        label: ANALYTICS.LABELS.ADD_TO_CART,
        'Test ID': product.productId,
        'Test Name': product.name,
        Experiment: experimentCookie ? experimentCookie : '',
      },
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    });
  };

  const handleCardClick = (slug: string) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.HOMEPAGE_POPULAR_PRODUCT_CARD,
        slug,
      },
    });
  };

  return (
    <S.Section>
      <S.MobileHeadline>{headline}</S.MobileHeadline>
      <S.InnerContent>
        <Themable>
          {/* @ts-ignore - the theme prop is only used for Themeable to find and filter */}
          <S.Img theme={Theme.Default} aria-hidden="true" src={lines} alt="" />
          <S.Img
            // @ts-ignore - the theme prop is only used for Themeable to find and filter
            theme={Theme.Winter2020}
            aria-hidden="true"
            src={snowflakes}
            style={{
              marginLeft: '-24px',
              bottom: `-8px`,
            }}
            alt=""
          />
        </Themable>
        <S.ContainerTests>
          <S.MobileCardsCol>
            <S.DesktopCardsContainer>
              {listOfContent.map((item, i) => {
                const productPromoData = allProductPromoData?.find(
                  (page) => page.slug === item.slug,
                );
                return (
                  <S.CardWrapper
                    key={`desktop-card-${i}`}
                    onClick={() => handleCardClick(item.slug)}
                  >
                    <Card.Product
                      image={
                        <S.CardImage image={item.image} alt={item.heading} />
                      }
                      imageAlt={`${item.heading} box image`} // @ts-ignore
                      productName={<S.CardTitle>{item.heading}</S.CardTitle>}
                      productUrl={
                        item.productLink ||
                        (productsWithMembership[item.slug] &&
                          `/products/${
                            productsWithMembership[item.slug].slug
                          }/`)
                      }
                      price={getPriceForMembershipProduct(
                        productsWithMembership[item.slug]?.price,
                        productsWithMembership[item.slug]?.memberPrice,
                      )}
                      isOutOfStock={false} // We don't have this data yet, so we set it to false
                      reviewData={
                        // get the review data from the identifier
                        item.showRating ? item.reviewData : null
                      }
                      // @ts-ignore
                      promoPrice={
                        productsWithMembership[item.slug]?.memberPrice || ''
                      }
                      promoText={
                        productsWithMembership[item.slug]?.promoText ||
                        productPromoData?.promoText
                      }
                      showAddToCart={false}
                      onAddToCartClick={() => handleAddToCartClick(item.slug)}
                      addedToCart={itemsInCart.includes(item.slug)}
                      onRemoveFromCartClick={() => null}
                      removedFromCart={!itemsInCart.includes(item.slug)}
                      description={''}
                      showReviewCount={false}
                      isOnSale={productPromoData?.onSale}
                      isNew={productsWithMembership[item.slug]?.isNew}
                      callout={item.callout}
                      renderLinkAs={customGatsbyLink}
                    />
                  </S.CardWrapper>
                );
              })}
            </S.DesktopCardsContainer>

            <S.ContentContainer>
              <S.DesktopHeadline>{headline}</S.DesktopHeadline>
              <S.DesktopShopCTAButton
                appearance="secondary"
                onClick={() => handleClick(mainCta.url)}
              >
                {mainCta.text}
              </S.DesktopShopCTAButton>
            </S.ContentContainer>
          </S.MobileCardsCol>
        </S.ContainerTests>
        <S.MobileShopCTAContainer>
          <Row center="xs">
            <S.MobileShopCTA
              appearance="secondary"
              onClick={() => handleClick(mainCta.url)}
            >
              {mainCta.text}
            </S.MobileShopCTA>
          </Row>
        </S.MobileShopCTAContainer>
      </S.InnerContent>
    </S.Section>
  );
};

export default PopularTestsSection;
