import { size, mediaQueries, typography, colors } from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-items: center;
  flex: 1;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-right: ${size.lg}px;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 0;
    align-items: center;
  }
`;

const IconContainer = styled(GatsbyImage)`
  width: ${size.xl1}px;
  height: ${size.xl1}px;

  picture img {
    object-fit: contain !important;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    margin-right: ${size.sm}px;
  }
`;

const Benefit = styled.div`
  width: 100%;
  ${typography.bodyTextSmall};
  color: ${colors.teal4};
  text-align: center;
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
    line-height: 26px;
    width: 125px;
  }
`;

export { Benefit, Container, IconContainer };
