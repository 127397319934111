import { Container } from '@everlywell/leaves';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';

import { DiscoveryCardProps } from './components/DiscoveryCard';
import * as S from './VirtualCareDiscoverySection.styles';

export type VirtualCareDiscoverySectionProps = {
  cards: DiscoveryCardProps[];
  ctaText: string;
  ctaUrl: string;
  mobileHeadline: string;
};

/**
 * Virtual Care discovery section configurable from contenftul. Part of the new landing page.
 */
function VirtualCareDiscoverySection({
  ctaText,
  ctaUrl,
  mobileHeadline,
  cards,
}: VirtualCareDiscoverySectionProps): JSX.Element {
  const handleCTAclick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.EVENTS.CLICKED_BUTTON,
        label: 'Homepage verticals booking button',
      },
    });
    return false;
  };

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'Homepage verticals booking button',
      },
    });
  }, []);

  return (
    <Container>
      <S.Container>
        <S.CardsContainer>
          {cards.map((card, index) => (
            <S.Card
              key={`variant-a-${index}-${card.title}`}
              title={card.title}
              image={card.image}
              text={card.text}
              url={card.url}
            />
          ))}
        </S.CardsContainer>
        <S.CTAContainer>
          <S.DesktopHeadline>{mobileHeadline}</S.DesktopHeadline>

          <S.CTAButton
            component={Link}
            href={ctaUrl}
            onClick={() => handleCTAclick()}
          >
            {ctaText}
          </S.CTAButton>
        </S.CTAContainer>
      </S.Container>
    </Container>
  );
}

export default VirtualCareDiscoverySection;
