import { TelehealthBenefitsList } from 'containers/Homepage/VirtualCareBenefitsContainer';
import React from 'react';

import * as S from './TelehealthBenefit.styles';

export interface TelehealthBenefitProps {
  benefit: TelehealthBenefitsList;
}

export const TelehealthBenefit: React.FC<TelehealthBenefitProps> = ({
  benefit,
}) => (
  <S.Container>
    <S.IconContainer
      alt={benefit.title}
      image={benefit.image.gatsbyImageData}
    />

    <S.Benefit>{benefit.title}</S.Benefit>
  </S.Container>
);

export default TelehealthBenefit;
