import { size, colors, mediaQueries, typography } from '@everlywell/leaves';
import { Link } from 'gatsby';
import styled from 'styled-components';

const CARD_HEIGHT_MOBILE = 115;
const CARD_HEIGHT_DESKTOP = 145;

export const LinkTextContainer = styled.span`
  ${typography.buttonText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${size.xs2}px;
`;

export const Container = styled(Link)`
  display: flex;
  gap: ${size.sm}px;
  width: 100%;
  height: ${CARD_HEIGHT_MOBILE}px;
  border-radius: ${size.xs1}px;
  box-shadow: 0 2px 20px -5px rgba(170, 169, 172, 0.4);
  border: 1px solid ${colors.teal1};
  text-decoration: none;
  background-color: ${colors.white};
  transition: all 0.2s ease-in-out;
  position: relative;
  padding: ${size.md}px;
  max-width: 300px;

  &:hover,
  &:active {
    transform: scale(1.03);

    ${LinkTextContainer} {
      color: ${colors.green5};
    }
  }

  ${mediaQueries.forTabletVerticalUp} {
    height: ${CARD_HEIGHT_DESKTOP}px;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
  }

  ${mediaQueries.forDesktopUp} {
    max-width: 300px;
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 60%;
`;

export const ImageContainer = styled.div`
  flex: 1;

  .mobile-image,
  .desktop-image {
    width: 100%;
    height: 100%;
  }

  .desktop-image {
    display: none;
  }

  ${mediaQueries.forTabletVerticalUp} {
    .mobile-image {
      display: none;
    }

    .desktop-image {
      display: block;
    }
  }
`;
