import {
  mediaQueries,
  Container as PageContainer,
  size,
  H3,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const MainContainer = styled(PageContainer)`
  max-width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: ${size.lg}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: column-reverse;
  }

  ${mediaQueries.forDesktopUp} {
    padding-top: ${size.xl1}px;
  }
`;

export const BenefitsContainer = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  margin-bottom: ${size.lg}px;
  margin-top: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
    margin-top: 0;
  }
`;
export const Headline = styled(H3)``;

export const SubHeadline = styled(Headline)``;
