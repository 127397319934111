import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ImageType } from 'utils/types';

import HeroCard, { HeroCardProps } from './HeroCard';
import * as S from './HeroWithCards.styles';

export type HeroWithCardsProps = {
  headline: string;
  subHeadline: string;
  cardsForSection: HeroCardProps[];
  backgroundColor: string;
  desktopImages: ImageType[];
  desktopHeight: number;
  tabletHeight: number;
};

/**
 * Hero with cards component to be used in the container.
 */
function HeroWithCards({
  backgroundColor,
  headline,
  subHeadline,
  cardsForSection,
  desktopImages,
  desktopHeight,
  tabletHeight,
}: HeroWithCardsProps): JSX.Element {
  const desktopImage = desktopImages[0];

  const handleCardClick = (text: string) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        sectionHeader: headline,
        contentfulSection: 'HeroWithCards',
        ctaText: text,
      },
    });
  };

  // Cards to be reused mobile and desktop
  const cardsToRender = cardsForSection.map((card) => (
    <HeroCard
      key={card.linkPath}
      cardImage={card.cardImage}
      cardImageMobile={card.cardImageMobile}
      linkText={card.linkText}
      linkPath={card.linkPath}
      callout={card.callout}
      onCardClick={handleCardClick}
    ></HeroCard>
  ));

  return (
    <S.Container
      backgroundColor={backgroundColor}
      desktopHeight={desktopHeight}
      tabletHeight={tabletHeight}
    >
      <S.ImageContainer tabletHeight={tabletHeight}>
        <GatsbyImage
          image={desktopImage.gatsbyImageData}
          alt={desktopImage.description || ''}
          class="main-image"
        ></GatsbyImage>
      </S.ImageContainer>
      <S.ContentContainer>
        <S.TextsContainer>
          <S.headline>{headline}</S.headline>
          <S.subHeadline>{subHeadline}</S.subHeadline>
        </S.TextsContainer>
        <S.CardsContainerMobile>{cardsToRender}</S.CardsContainerMobile>
      </S.ContentContainer>
      <S.CardsContainerTabletUp>{cardsToRender}</S.CardsContainerTabletUp>
    </S.Container>
  );
}

export default HeroWithCards;
