import VirtualCareDiscoverySection from 'components/Homepage/VirtualCareDiscoverySection';
import { DiscoveryCardProps } from 'components/Homepage/VirtualCareDiscoverySection/components/DiscoveryCard';
import React from 'react';

import { ContentWithImage } from '../../../utils/types';

export type VirtualCareBenefitsList = {
  title: string;
  image: JSX.Element;
  imageAlt: string;
};

export interface VirtualCareDiscoveryContainerProps {
  content: {
    headline: string;
    listOfContentWithImages: ContentWithImage[];
    mainCta: {
      text: string;
      url: string;
    };
  };
}

export const VirtualCareDiscoveryContainer: React.FC<
  VirtualCareDiscoveryContainerProps
> = ({ content }) => {
  if (!content) {
    return null;
  }

  const { headline, mainCta, listOfContentWithImages } = content;

  const cards: DiscoveryCardProps[] = listOfContentWithImages.map((item) => ({
    title: item.heading || '',
    url: item.cta?.url || '',
    image: item.image?.gatsbyImageData,
  }));

  return (
    <VirtualCareDiscoverySection
      cards={cards}
      ctaText={mainCta.text}
      ctaUrl={mainCta.url}
      mobileHeadline={headline}
    />
  );
};

export default VirtualCareDiscoveryContainer;
