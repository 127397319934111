import { colors } from '@everlywell/leaves';
import HeroWithCards, { HeroWithCardsProps } from 'components/HeroWithCards';
import React from 'react';

import * as S from './HeroWithCardsContainer.styles';

export type HeroWithCardsContainerProps = {
  /** This is the text that is displayed in the component*/
  content?: HeroWithCardsProps;
};

/**
 * Hero container for the hero with cards component
 */
export default function HeroWithCardsContainer({
  content,
}: HeroWithCardsContainerProps): JSX.Element | null {
  if (!content) {
    return null;
  }

  const {
    backgroundColor,
    headline,
    subHeadline,
    desktopImages,
    cardsForSection,
  } = content;

  return (
    <S.Container>
      <HeroWithCards
        backgroundColor={backgroundColor || colors.green5}
        headline={headline}
        subHeadline={subHeadline}
        desktopImages={desktopImages}
        cardsForSection={cardsForSection}
        desktopHeight={S.DESKTOP_HEIGHT}
        tabletHeight={S.TABLET_HEIGHT}
      ></HeroWithCards>
    </S.Container>
  );
}
