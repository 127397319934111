import BaseHero from 'components/Hero/BaseHero';
import TelehealthBenefit from 'components/TelehealthBenefit';
import { Link } from 'gatsby';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ContentWithImage, CTA, ImageType } from 'utils/types';

import * as S from './VirtualCareHero.styles';

export type VirtualCareHeroProps = {
  headline?: string;
  subHeadline?: string;
  mainCta?: CTA;
  listOfLinks?: CTA[];
  desktopImage?: ImageType;
  mobileImage?: ImageType;
  contentList?: ContentWithImage[];
};

/**
 * The Virtual Care Hero renders a left align image with a right aligned text and a CTA.
 */
function VirtualCareHero({
  headline,
  subHeadline,
  mainCta,
  desktopImage,
  mobileImage,
  contentList,
}: VirtualCareHeroProps): JSX.Element {
  const handleCTAClick = (label: string) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `Homepage hero booking button ${label}`,
      },
    });
  };

  const children = (
    <S.MainContainer>
      <S.Container>
        <>
          <S.ContentContainer>
            <div>
              <S.Header>
                {headline} <strong>{subHeadline}</strong>
              </S.Header>
            </div>
          </S.ContentContainer>
          <div>
            {mainCta && (
              <S.CTA
                onClick={() => handleCTAClick(mainCta.text)}
                component={Link}
                href={mainCta.url}
              >
                {mainCta.text}
              </S.CTA>
            )}

            <S.ContentListSection>
              {contentList?.map((benefit) => (
                <TelehealthBenefit
                  key={benefit.heading}
                  benefit={{
                    title: benefit.heading,
                    image: {
                      gatsbyImageData: benefit.image?.gatsbyImageData,
                      description: benefit.image?.description || '',
                    },
                  }}
                />
              ))}
            </S.ContentListSection>
          </div>
        </>
      </S.Container>
    </S.MainContainer>
  );

  return (
    <div style={{ maxWidth: '1440px', margin: 'auto', width: '100%' }}>
      <BaseHero
        backgroundImage={desktopImage?.gatsbyImageData}
        mobileBackgroundImage={mobileImage?.gatsbyImageData}
        verticalCenter
        objectFit="contain"
        objectPosition="right bottom"
      >
        {children}
      </BaseHero>
    </div>
  );
}

export default VirtualCareHero;
