import TelehealthBenefit from 'components/TelehealthBenefit';
import { TelehealthBenefitsList } from 'containers/Homepage/VirtualCareBenefitsContainer';
import React from 'react';

import * as S from './VirtualCareBenefitsSection.styles';

export interface VirtualCareBenefitsSectionProps {
  benefits: TelehealthBenefitsList[];
  headline: string;
  subHeadline: string;
}

export const VirtualCareBenefitsSection: React.FC<
  VirtualCareBenefitsSectionProps
> = ({ benefits, headline, subHeadline }) => (
  <S.MainContainer>
    <S.Container>
      <S.TextContainer>
        <S.Headline>
          {headline} <br /> {subHeadline}
        </S.Headline>
      </S.TextContainer>
      <S.BenefitsContainer>
        {benefits.map((benefit) => (
          <TelehealthBenefit key={benefit.title} benefit={benefit} />
        ))}
      </S.BenefitsContainer>
    </S.Container>
  </S.MainContainer>
);

export default VirtualCareBenefitsSection;
