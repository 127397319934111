import { colors } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CTA, IGatsbyImage } from 'utils/types';

import * as S from './ProgramAdSection.styles';

export type ProgramAdSectionProps = {
  slug: string;
  headline: string;
  subHeadline: string;
  mainCta?: CTA;
  desktopImages: IGatsbyImage[];
  mobileImages: IGatsbyImage[];
  mobileTextColor?: string;
};

function ProgramAdSection({
  slug,
  headline,
  subHeadline,
  mainCta,
  desktopImages,
  mobileImages,
  mobileTextColor,
}: ProgramAdSectionProps): JSX.Element {
  const desktopImage = desktopImages && desktopImages[0];
  const mobileImage = mobileImages && mobileImages[0];

  const handleClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.HOMEPAGE.PROGRAM_AD_CLICK,
        program: slug,
      },
    });
  };

  return (
    <SectionWrapper id={slug}>
      <S.Container>
        <S.ImageContainer>
          {desktopImage ? (
            <S.DesktopImageWrapper>
              <GatsbyImage
                image={desktopImage.gatsbyImageData}
                loading="eager"
                alt={
                  desktopImage.description ||
                  'program advertisement desktop image'
                }
              />
            </S.DesktopImageWrapper>
          ) : null}
          {mobileImage ? (
            <S.MobileImageWrapper>
              <GatsbyImage
                image={mobileImage.gatsbyImageData}
                loading="eager"
                alt={
                  mobileImage.description ||
                  'program advertisement mobile image'
                }
              />
            </S.MobileImageWrapper>
          ) : null}
        </S.ImageContainer>
        <S.CopyAndCTAContainer>
          <S.Headline mobileTextColor={mobileTextColor ?? `${colors.black}`}>
            {headline}
          </S.Headline>
          <S.SubHeadline mobileTextColor={mobileTextColor ?? `${colors.black}`}>
            {subHeadline}
          </S.SubHeadline>
          {mainCta ? (
            <S.CTAContainer>
              <S.CTAButton
                appearance="secondary"
                href={mainCta.url}
                onClick={() => handleClick()}
              >
                {mainCta.text}
              </S.CTAButton>
            </S.CTAContainer>
          ) : null}
        </S.CopyAndCTAContainer>
      </S.Container>
    </SectionWrapper>
  );
}

export default ProgramAdSection;
