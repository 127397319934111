import {
  colors,
  Button,
  mediaQueries,
  typography,
  size,
  Container as BaseContainer,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(BaseContainer)`
  position: relative;

  ${mediaQueries.forPhoneOnly} {
    /** 
    * Needs this padding styling for mobile view only as
    * the background image should stretch to fit the container
    */
    padding: 0 !important;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 50%;
  }
`;

export const CopyAndCTAContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  margin: 0 auto;
  padding: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    top: 20%;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    width: 415px;
    position: unset;
    margin: auto;
  }
`;

export const Headline = styled.div<{ mobileTextColor: string }>`
  /** 
   * Only the Headline text color in Mobile view can be changed in Contentful 
   */
  ${typography.h2Text}
  color: ${({ mobileTextColor }) => mobileTextColor};
  width: 280px;
  height: 88px;
  left: ${size.md}px;
  top: 660px;
  text-align: left;

  ${mediaQueries.forTabletVerticalUp} {
    width: 300px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.h2Text};
    width: 350px;
    height: auto;
  }
`;

export const SubHeadline = styled.div<{ mobileTextColor: string }>`
  /** 
   * Only the SubHeadline text color in Mobile view can be changed in Contentful 
   */
  ${typography.bodyTextSmall}
  color: ${({ mobileTextColor }) => mobileTextColor};
  font-weight: ${typography.weight.bold};
  width: 200px;
  height: auto;
  left: ${size.md}px;
  top: 755px;
  text-align: left;
  margin: ${size.xs1}px 0 ${size.sm}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    width: 300px;
    padding: ${size.lg}px 0 ${size.sm}px 0;
    margin: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.bodyText}
    width: 100%;
    padding: ${size.md}px 0;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 325px;
`;

export const CTAButton = styled(Button)`
  border-color: ${colors.white};
  color: ${colors.black};
  background-color: ${colors.white};
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${colors.green3};
  }

  ${mediaQueries.forTabletHorizontalUp} {
    border-color: ${colors.green3};
    color: ${colors.white};
    background-color: ${colors.green4};
    width: 100%;

    &:hover,
    &:focus {
      background-color: ${colors.green3};
    }
  }
`;

export const DesktopImageWrapper = styled.div`
  display: none;

  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
  }
`;

export const MobileImageWrapper = styled.div`
  display: block;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;
