import React from 'react';

import { FAQSection } from '../../../components/FAQSection';
import { ContentWithImage } from '../../../utils/types';

export interface FAQContainerProps {
  content: {
    title: string;
    headline: string;
    listOfContentWithImages: ContentWithImage[];
  };
}
export const FAQContainer: React.FC<FAQContainerProps> = ({ content }) => {
  if (!content) return null;
  const { title, listOfContentWithImages } = content;

  const props = {
    // We have to munge this data into the format the component expects
    faqs: listOfContentWithImages.map(({ heading, description }) => {
      // ContentWithImage type needs to allow for `undefined` values in
      // `description`, but our FAQ type requires a string, so we supply
      // a default value '' to the component. Should this throw at build time?
      return {
        question: heading,
        answer: description?.childMarkdownRemark.html || '',
      };
    }),
    title,
  };

  return <FAQSection {...props} />;
};

export default FAQContainer;
