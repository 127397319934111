import {
  Button,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import DiscoveryCard from './components/DiscoveryCard';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${size.lg}px 0 ${size.md}px 0;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  ${mediaQueries.forDesktopUp} {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 0 ${size.lg}px;
    align-items: center;
    margin-top: ${size.xl4}px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${colors.teal4};
  margin-bottom: ${size.sm}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const Card = styled(DiscoveryCard)``;

const cardsSortStyles = css`
  &:nth-child(odd) {
    /**
        * Sort the cards based on the viewport.
        * So in mobile looks like a matrix of 4 rows 2 columns, and in desktop
        * it is the transpose of that matrix.
        *
        * Given a list of images like: [i1, i2, i3, i4, i5, i6, i7, i8]
        * When the screen size is mobile
        * Then it is rendered in 2 columns like:
        * [
        *  i1, i2
        *  i3, i4
        *  i5, i6
        *  i7, i8
        * ]
        *
        * Given a list of images like: [i1, i2, i3, i4, i5, i6, i7, i8]
        * When the screen size is NOT mobile
        * Then it is rendered in 2 rows like:
        * [
        *  i1, i3, i5, i7,
        *  i2, i4, i6, i8,
        * ]
        * This way odd items are forced to be in the first row
        */
    ${mediaQueries.forTabletHorizontalUp} {
      order: -1;
    }
  }
`;

export const CardsContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${size.md}px;
  grid-column-gap: ${size.md}px;
  margin-bottom: ${size.xl2}px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    margin-top: ${size.xl2}px;
    margin-bottom: 0;
  }

  ${mediaQueries.forDesktopUp} {
    margin-top: 0;
    max-width: 54%;
  }

  ${Card} {
    ${cardsSortStyles}
  }
`;

export const CardsContainerVariationA = styled.div`
  flex: 1;
  display: grid;
  grid-template-areas: 'a a' 'b b';
  grid-row-gap: ${size.md}px;
  grid-column-gap: ${size.md}px;
  margin-bottom: ${size.xl2}px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-areas: none;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr repeat(3, 1fr);
    width: 100%;
    margin-top: ${size.xl2}px;
    margin-bottom: 0;
  }

  ${mediaQueries.forDesktopUp} {
    margin-top: 0;
    max-width: 873px;
  }

  ${Card} {
    &:nth-child(1) {
      grid-area: a;

      ${mediaQueries.forTabletHorizontalUp} {
        grid-area: unset;
      }
    }

    &:nth-child(2) {
      grid-area: b;

      ${mediaQueries.forTabletHorizontalUp} {
        grid-area: unset;
      }
    }

    ${cardsSortStyles}

    &:nth-child(1),
    &:nth-child(2) {
      img {
        object-position: top;
      }
    }
    .gatsby-image-wrapper-constrained {
      display: block;
    }
  }
`;

export const CTAContainer = styled.div`
  ${mediaQueries.forDesktopUp} {
    margin-bottom: ${size.xl1}px;
  }
`;
export const DesktopHeadline = styled.div`
  display: none;

  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 500px;
    display: flex;
    color: ${colors.teal4};
    font-weight: ${typography.weight.bold};
    font-size: ${size.xl2}px;
    margin-bottom: ${size.xl1}px;
  }
`;

export const CTAButton = styled(Button)`
  background-color: ${colors.teal4};
  letter-spacing: 0.25px;
  font-weight: ${typography.weight.bold};
  font-size: 18px;
  line-height: ${size.lg}px;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 325px;
  }
`;
