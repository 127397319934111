import { mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const DESKTOP_HEIGHT = 480;
export const TABLET_HEIGHT = 530;

export const Container = styled.section`
  display: flex;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    height: ${TABLET_HEIGHT + Number(size.xl4)}px;
  }

  ${mediaQueries.forDesktopUp} {
    height: ${DESKTOP_HEIGHT + Number(size.xl4)}px;
  }
`;
