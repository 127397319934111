import PopularTestsSection from 'components/Homepage/PopularTestsSection';
import React from 'react';
import { CTA, ListOfContentWithImages, ProductsBySlugType } from 'utils/types';
export interface PopularTestsContainerProps {
  content: {
    headline: string;
    mainCta: CTA;
    listOfContentWithImages: ListOfContentWithImages[];
  };
  productsBySlug: ProductsBySlugType;
}

export const PopularTestsContainer = ({
  content,
  productsBySlug,
}: PopularTestsContainerProps): JSX.Element | null => {
  if (!content) {
    return null;
  }

  const { headline, mainCta, listOfContentWithImages } = content;

  const formattedListOfContent = listOfContentWithImages.map((item) => {
    const product = productsBySlug[item.identifier];

    const priceAsNumber = Number(product?.price);

    return {
      heading: product?.name,
      subheading: `$${priceAsNumber.toFixed()}`,
      callout: item.callout,
      image: product?.boxImage?.gatsbyImageData,
      number: product?.productId,
      slug: item?.identifier || '',
      // Only hide rating if it comes as false from contentful. Null or true means: show it
      showRating: product?.showRating === null || product?.showRating === true,
      description: item?.description?.description,
      reviewData: {
        numOfReviews: product?.reviewData?.review_count,
        rating: product?.reviewData?.average_rating,
      },
      // Use identifier as link if the price is zero
      productLink: priceAsNumber > 0 ? undefined : item.identifier,
    };
  });

  return (
    <PopularTestsSection
      headline={headline}
      mainCta={mainCta}
      listOfContent={formattedListOfContent}
      productsBySlug={productsBySlug}
    />
  );
};

export default PopularTestsContainer;
