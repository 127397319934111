import {
  colors,
  Button,
  H2,
  size,
  mediaQueries,
  Container as PageContainer,
  typography,
} from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const MainContainer = styled(PageContainer)`
  ${mediaQueries.forTabletVerticalDown} {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  max-width: 100%;
  padding: ${size.lg}px;
  position: relative;
  justify-content: space-between;
  min-height: 375px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl2}px ${size.lg}px ${size.lg}px;
    justify-content: flex-end;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl1}px 0;
    height: auto;
    margin: 0 auto;
    min-height: 500px;
  }
`;

export const ContentContainer = styled.div`
  min-height: 224px;

  ${mediaQueries.forTabletVerticalUp} {
    min-height: 254px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-direction: row;
  }
`;

export const Header = styled(H2)`
  color: ${colors.green4};
  max-width: 225px;
  font-weight: ${typography.weight.bold};
  font-size: ${size.xl1}px;
  line-height: ${size.xl2}px;
  margin-bottom: ${size.sm}px;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 50%;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.xl4}px;
    line-height: 88px;
  }

  ${mediaQueries.forDesktopUp} {
    max-width: 700px;
  }

  strong {
    font-style: italic;
    color: ${colors.teal4};
    display: block;
    margin-top: ${size.xs1}px;

    ${mediaQueries.forTabletVerticalUp} {
      margin-top: ${size.lg}px;
    }
  }
`;

export const DesktopImage = styled(GatsbyImage)`
  &,
  &.gatsby-image-wrapper,
  &.gatsby-image-wrapper-constrained {
    display: none;

    ${mediaQueries.forTabletHorizontalUp} {
      display: block;
      position: absolute;
      width: 472px;
      height: 472px;
      right: 0;
      top: 57px;
      z-index: -1;
    }
  }
`;

export const MobileImage = styled(GatsbyImage)`
  &,
  &.gatsby-image-wrapper,
  &.gatsby-image-wrapper-constrained {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 375px;
    z-index: -1;

    ${mediaQueries.forTabletVerticalUp} {
      width: 460px;
    }

    ${mediaQueries.forTabletHorizontalUp} {
      display: none;
    }
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    margin-top: 0;
  }
`;

export const CTA = styled(Button)`
  width: 100%;
  height: ${size.xl3}px;
  font-weight: ${typography.weight.bold};

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 327px;
    margin-top: ${size.md}px;
    margin-right: ${size.md}px;
  }

  :nth-child(2) {
    ${mediaQueries.forTabletVerticalUp} {
      width: fit-content;
    }
    ${mediaQueries.forPhoneOnly} {
      margin-top: ${size.md}px;
    }
  }
`;

export const ContentListSection = styled.div`
  display: none;

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    padding-top: ${size.lg}px;
  }
`;
