import { HomepageHero } from 'components/Hero';
import React from 'react';

import { HeroSectionType } from '../../../utils/types';

export interface HeroContainerProps {
  content: HeroSectionType;
}

export const HeroContainer = ({
  content,
}: HeroContainerProps): JSX.Element | null => {
  if (!content) {
    return null;
  }

  const {
    headlineMarkdown,
    descriptionMarkdown,
    backgroundColor,
    desktopImage,
    mobileImage,
    mainCta,
    secondaryCtaList,
    secondaryHeadline,
    topBanner,
    pinImageToBottom,
    pinImageToRight,
    imageHeight,
    textColor,
  } = content;

  const formattedTopBanner = {
    link: topBanner?.mainCta.url || '',
    text: topBanner?.headline || '',
    ctaText: topBanner?.mainCta.text || '',
  };

  return (
    <div>
      <HomepageHero
        headline={headlineMarkdown}
        description={descriptionMarkdown}
        mainCta={mainCta}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        secondaryCtaList={secondaryCtaList}
        secondaryHeadline={secondaryHeadline}
        backgroundColor={backgroundColor}
        topBanner={formattedTopBanner}
        imageHeight={imageHeight}
        pinImageToBottom={pinImageToBottom}
        pinImageToRight={pinImageToRight}
        textColor={textColor}
      />
    </div>
  );
};

export default HeroContainer;
