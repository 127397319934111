import {
  colors,
  typography,
  size,
  Badge as LeavesBadge,
} from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Container = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  background: ${colors.white};
  border: 1px solid #eef5f6;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  border-radius: ${size.xs2}px;
  overflow: hidden;
  text-decoration: none;

  img {
    transition: all 0.3s ease !important;
  }

  &:hover {
    img {
      scale: 1.1;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.3s ease !important;
`;

export const Image = styled(GatsbyImage)`
  height: 33vw;

  ${(mediaQuery) => mediaQuery.theme.forTabletHorizontalUp} {
    max-height: 122px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${size.sm}px ${size.xs1}px;
`;
export const Title = styled.h4`
  letter-spacing: 0.25px;
  color: ${colors.green4};
  font-weight: 600;
  font-size: 16px;
  line-height: ${size.lg}px;
  margin-bottom: ${size.xs2}px;
  text-decoration: none;
  position: relative;

  ${(mediaQuery) => mediaQuery.theme.forDesktopUp} {
    padding-right: 8%;
  }
`;

export const Text = styled.p`
  font-weight: ${typography.weight.light};
  color: ${colors.gray4};
  letter-spacing: 0.5px;
`;

export const Badge = styled(LeavesBadge)`
  position: absolute;
  top: ${size.xs1}px;
  right: ${size.xs1}px;
  z-index: 1;
`;
