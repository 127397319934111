import loadable, { LoadableComponent } from '@loadable/component';
import { CategorySectionProps } from 'components/Homepage/CategorySection/types';
import VirtualCareHeroSection, {
  VirtualCareHeroProps,
} from 'components/Homepage/VirtualCareHero';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, { createContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useChain, useSpringRef, SpringRef } from 'react-spring';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { Flags } from 'utils/constants/featureFlags';
import {
  useHomepageData,
  useProductVariants,
  useProductBySlug,
  usePromoData,
} from 'utils/hooks';
import {
  CTA,
  ListOfContentWithImages,
  PageSectionType,
  ProductsBySlugType,
} from 'utils/types';

import Layout from '../components/Layout';
import { SEO } from '../components/SEO';
import { HeroCarouselProps } from '../containers/HeroCarousel';
import HeroWithCards, {
  HeroWithCardsContainerProps,
} from '../containers/HeroWithCardsContainer';
import { BusinessPartnerContainerProps } from '../containers/Homepage/BusinessPartnerContainer';
import { ByTheNumbersContainerProps } from '../containers/Homepage/ByTheNumbersContainer';
import { FAQContainerProps } from '../containers/Homepage/FAQContainer';
import FAQSection from '../containers/Homepage/FAQContainer';
import {
  HeroContainer as HeroSection,
  HeroContainerProps,
} from '../containers/Homepage/HeroContainer';
import { HowItWorksContainerProps } from '../containers/Homepage/HowItWorksContainer';
import { LatestUpdatesContainerProps } from '../containers/Homepage/LatestUpdatesContainer';
import {
  PopularTestsContainer as PopularTestsSection,
  PopularTestsContainerProps,
} from '../containers/Homepage/PopularTestsContainer';
import { PressContainerProps } from '../containers/Homepage/PressContainer';
import {
  ProgramAdContainer as ProgramAdSection,
  ProgramAdContainerProps,
} from '../containers/Homepage/ProgramAdContainer';
import { RecommendedArticlesContainerProps } from '../containers/Homepage/RecommendedArticlesContainer';
import { TestimonialContainerProps } from '../containers/Homepage/TestimonialContainer';
import {
  VirtualCareBenefitsContainer as VirtualCareBenefitsSection,
  VirtualCareBenefitsContainerProps,
} from '../containers/Homepage/VirtualCareBenefitsContainer';
import {
  VirtualCareDiscoveryContainer as VirtualCareDiscoverySection,
  VirtualCareDiscoveryContainerProps,
} from '../containers/Homepage/VirtualCareDiscoveryContainer';
import { WhatToExpectContainerProps } from '../containers/Homepage/WhatToExpectContainer';
import useDisplayedSectionFilterForMembers from '../utils/hooks/useDisplayedSectionFilterForMembers';

const BusinessPartnerSection = loadable(
  () => import('../containers/Homepage/BusinessPartnerContainer'),
);
const ByTheNumbersSection = loadable(
  () => import('../containers/Homepage/ByTheNumbersContainer'),
);
const HowItWorksSection = loadable(
  () => import('../containers/Homepage/HowItWorksContainer'),
);
const LatestUpdatesSection = loadable(
  () => import('../containers/Homepage/LatestUpdatesContainer'),
);
const PressSection = loadable(
  () => import('../containers/Homepage/PressContainer'),
);
const CategorySection = loadable(
  () => import('../containers/Homepage/CategoryContainer'),
);
const RecommendedArticlesSection = loadable(
  () => import('../containers/Homepage/RecommendedArticlesContainer'),
);
const TestimonialSection = loadable(
  () => import('../containers/Homepage/TestimonialContainer'),
);
const WhatToExpectSection = loadable(
  () => import('../containers/Homepage/WhatToExpectContainer'),
);
const CarouselItems = loadable(() => import('../containers/HeroCarousel'));

const components: {
  HeroSection: React.FunctionComponent<HeroContainerProps>;
  HeroWithCards: React.FunctionComponent<HeroWithCardsContainerProps>;
  PopularTestsSection: React.FunctionComponent<PopularTestsContainerProps>;
  CategorySection: LoadableComponent<CategorySectionProps>;
  HowItWorksSection: LoadableComponent<HowItWorksContainerProps>;
  WhatToExpectSection: LoadableComponent<WhatToExpectContainerProps>;
  LatestUpdatesSection: LoadableComponent<LatestUpdatesContainerProps>;
  TestimonialSection: LoadableComponent<TestimonialContainerProps>;
  ByTheNumbersSection: LoadableComponent<ByTheNumbersContainerProps>;
  RecommendedArticlesSection: LoadableComponent<RecommendedArticlesContainerProps>;
  PressSection: LoadableComponent<PressContainerProps>;
  BusinessPartnerSection: LoadableComponent<BusinessPartnerContainerProps>;
  FAQSection: React.FunctionComponent<FAQContainerProps>;
  CarouselItems: LoadableComponent<HeroCarouselProps>;
  VirtualCareHeroSection: React.FunctionComponent<VirtualCareHeroProps>;
  VirtualCareBenefitsSection: React.FunctionComponent<VirtualCareBenefitsContainerProps>;
  VirtualCareDiscoverySection: React.FunctionComponent<VirtualCareDiscoveryContainerProps>;
  ProgramAdSection: React.FunctionComponent<ProgramAdContainerProps>;
} = {
  HeroSection,
  HeroWithCards,
  PopularTestsSection,
  CategorySection,
  HowItWorksSection,
  WhatToExpectSection,
  LatestUpdatesSection,
  TestimonialSection,
  BusinessPartnerSection,
  ByTheNumbersSection,
  RecommendedArticlesSection,
  PressSection,
  FAQSection,
  CarouselItems,
  VirtualCareHeroSection,
  VirtualCareBenefitsSection,
  VirtualCareDiscoverySection,
  ProgramAdSection,
};

interface ProductPromoData {
  slug: string;
  onSale: boolean;
  promoText: string;
}

interface FooterProps {
  pageContext: {
    showAvailableForSelectConditionsDisclaimer?: boolean;
    showMedicareDisclaimer?: boolean;
  };
}

export interface RefsContextType {
  [key: string]: SpringRef;
}

export const ProductInfoContext = createContext<ProductsBySlugType>({});
export const RefsForAnimationContext = createContext<RefsContextType>({});
export const AllProductPromosContext = createContext<ProductPromoData[]>([]);

const isHeroWithCardsOn = (sections: PageSectionType[]) => {
  let shouldShowHeroWithCards = false;

  for (let index = 0; index < sections.length; index++) {
    if (
      sections[index].componentName === 'HeroWithCards' &&
      sections[index].featureFlag
    ) {
      shouldShowHeroWithCards = true;
      break;
    }
  }

  return shouldShowHeroWithCards;
};

export const HomepageTemplate: React.FC<FooterProps> = ({ pageContext }) => {
  const {
    showAvailableForSelectConditionsDisclaimer = true,
    showMedicareDisclaimer = true,
  } = pageContext;
  const contentfulPage = useHomepageData();
  const { sections, seoHeader } = contentfulPage;
  const productVariants = useProductVariants();
  const productsBySlug = useProductBySlug(productVariants);
  const allProductPromoData = usePromoData();
  const flags = useFlags();

  // Refs will be passed down in RefsForAnimationContext
  const animation1 = useSpringRef();
  const animation2 = useSpringRef();
  const animation3 = useSpringRef();
  const animation4 = useSpringRef();

  // by default useChain will space each animation 500ms apart
  // the 2nd argument overrides this default and defines the timing of the animations:
  // 1st animation happens immediately - 2nd animation starts 450ms after first animation ends
  // 3rd and 4th animations start 750ms after the first animation ends
  useChain([animation1, animation2, animation3, animation4], [0, 0.2, 1, 1]);

  // displayedSections is an array of PageSectionType
  // If the user is an active member, we filter out sections based on the slug
  const [displayedSections] = useDisplayedSectionFilterForMembers(sections);
  useEffect(() => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        Page: 'Homepage',
      },
    });
  }, []);

  const hasHeroWithCards = isHeroWithCardsOn(displayedSections);

  const trackScrolledIntoView = (inView: boolean) => {
    if (inView) {
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.SCROLLED_TO_BOTTOM,
        data: {
          label: ANALYTICS.LABELS.HOMEPAGE,
        },
      });
    }
  };

  const [footerRef] = useInView({
    triggerOnce: true,
    onChange: trackScrolledIntoView,
  });

  if (!sections) {
    return null;
  }

  const componentsToNotRender: string[] = [];

  if (!flags[Flags.HomepageCarousel]) {
    // Matches contentful Component Name
    componentsToNotRender.push('CarouselItems');
  }

  if (!hasHeroWithCards) {
    componentsToNotRender.push('HeroWithCards');
  }

  let SEOprops = {
    title: contentfulPage.title,
    description: '',
    canonicalUrl: '',
  };
  if (seoHeader) {
    SEOprops.description = seoHeader.metaDescription?.metaDescription;
    SEOprops.canonicalUrl = seoHeader.canonicalUrl;
  }

  const getComponent = (section: PageSectionType) => {
    switch (section.componentName) {
      case 'PopularTestsSection': {
        return (
          <PopularTestsSection
            content={{
              headline: section.headline as string,
              mainCta: section.mainCta as CTA,
              listOfContentWithImages:
                section.listOfContentWithImages as ListOfContentWithImages[],
            }}
            productsBySlug={productsBySlug}
          />
        );
      }
      case 'VirtualCareHeroSection':
        return (
          <VirtualCareHeroSection
            headline={section.headline}
            subHeadline={section.subHeadline}
            mobileImage={section.mobileImages?.[0]}
            desktopImage={section.desktopImages?.[0]}
            mainCta={section.mainCta}
            listOfLinks={section.listOfLinks}
            contentList={section.listOfContentWithImages}
          />
        );
      default:
        return React.createElement(
          // @ts-ignore
          components[section.componentName],
          {
            content: section,
          },
        );
    }
  };

  return (
    <>
      <SEO {...SEOprops} />
      <Layout
        productsBySlug={productsBySlug}
        showAvailableForSelectConditionsDisclaimer={
          showAvailableForSelectConditionsDisclaimer
        }
        showMedicareDisclaimer={showMedicareDisclaimer}
        footerRef={footerRef}
      >
        <ProductInfoContext.Provider value={productsBySlug}>
          <AllProductPromosContext.Provider value={allProductPromoData}>
            <RefsForAnimationContext.Provider
              value={{ animation1, animation2, animation3, animation4 }}
            >
              {displayedSections.map((section: PageSectionType, i: number) => {
                if (
                  // @ts-ignore
                  components[section.componentName] &&
                  !componentsToNotRender.includes(section.componentName)
                ) {
                  return getComponent(section);
                }

                return null;
              })}
            </RefsForAnimationContext.Provider>
          </AllProductPromosContext.Provider>
        </ProductInfoContext.Provider>
      </Layout>
    </>
  );
};

export default HomepageTemplate;
