import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import React from 'react';
import { CTA, IGatsbyImage } from 'utils/types';

import ProgramAdSection from '../../../components/Homepage/ProgramAdSection/ProgramAdSection';

export interface ProgramAdContainerProps {
  content: {
    sectionId: string;
    slug: string;
    title: string;
    headline: string;
    subHeadline: string;
    mainCta: CTA;
    desktopImages: IGatsbyImage[];
    mobileImages: IGatsbyImage[];
    mobileTextColor: string;
  };
}

export const ProgramAdContainer: React.FC<ProgramAdContainerProps> = ({
  content,
}) => {
  if (!content) return null;

  const {
    sectionId,
    slug,
    headline,
    subHeadline,
    mainCta,
    desktopImages,
    mobileImages,
    mobileTextColor,
  } = content;

  const props = {
    slug,
    headline,
    subHeadline,
    mainCta,
    desktopImages,
    mobileImages,
    mobileTextColor,
  };

  return (
    <TrackVisibilityWrapper label={sectionId} slug={slug}>
      <ProgramAdSection {...props} />
    </TrackVisibilityWrapper>
  );
};

export default ProgramAdContainer;
